/* div.timeline_container div.ant-timeline-item-label {
  width: calc(18% - 12px) !important;
} */
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(18% - 12px) !important;
}

/* div.timeline_container div.ant-timeline-item-content {
  left: calc(19% - 4px) !important;
  width: calc(79% - 4px) !important;
} */
.ant-timeline-item-content {
  left: calc(19% - 4px) !important;
  width: calc(79% - 4px) !important;
}

/* div.timeline_container div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 19% !important;
} */

.ant-timeline-item-head,
.ant-timeline-item-tail {
  left: 19% !important;
}
